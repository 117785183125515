$SquareLight: 'NanumSquareLight';
$SquareNomal: 'NanumSquare';
$SquareSemiBold: 'NanumSquareBold';
$SquareBold: 'NanumSquareExtraBold';

.logo-header {
  width: 150px;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    width: 120px;
  }
}

.logo-service-header {
  width: 200px;

  @media screen and (max-width: 480px) {
    width: 150px;
  }
}

.landing-grid-card {
  min-width: 190px;
  background-color: #f3f4ff;
  border-radius: 5px;
  padding: 15px 18px;
  cursor: pointer;

  // transition: all 300ms ease-in-out;
  &:hover {
    // box-shadow: 3px 3px 3px #ededed;

    & p {
      color: #2c2c2c;
    }
  }
}

.avatar-header {
  width: 100%;
  height: 100%;
}

.error404-button {
  // background-color: #4046b7;
  // border: 1px solid #4046b7;

  background-color: #6200ee;
  border: 1px solid #6200ee;

  border-radius: 15px;
  width: 100%;
  color: #fff;
  font-size: 1rem;
  padding: 3px 15px;
  cursor: pointer;
  margin-top: 25px;
  transition: all 300ms ease;

  &:hover {
    //   background-color:#7f39fb;
    background-color: #7f39fb;
  }
}

.error404-image {
  width: 250px;

  @media screen and (max-width: 480px) {
    width: 180px;
  }
}

// outlet 쪽 공통

.service-simpleDes {
  margin-bottom: 20px;
  font-size: 17px;
}

.result-zone {
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  border-left: 1px solid hsl(0, 0%, 87%);
  overflow-y: auto;

  @media screen and (max-width: 1200px) {
    max-width: 960px;
    margin: 0 auto;
    border: 0;
    border-top: 1px solid #dedede;
    min-height: 300px;
    // overflow-y: initial;
  }
}

.result-box {
  padding: 25px 20px 30px 20px;
  line-height: 28px;

  > hr,
  > div > hr {
    margin: 15px 0;
  }

  @media screen and (max-width: 1200px) {
    padding: 30px;
  }
}

.label-flex {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-weight: 600;
}

.result-icons {
  > svg {
    cursor: pointer;
    color: #3b2477;
    // background-color: #f3f3f3;
    // border: 1px solid #dedede;
    border-radius: 5px;
    transition: all 300ms ease-in-out;

    &:hover {
      color: #6200ee;
    }
  }
}

// login

.sign-link {
  font-size: 14px;
  text-align: center;

  > a {
    text-decoration: underline;

    &:hover {
      font-weight: 600;
    }
  }
}

.line {
  height: 1px;
  width: 50%;
  border-bottom: 1px solid #aaa;
}

.orLink {
  padding: 0 12px;
  color: #aaa;
}

.sign-btns {
  box-shadow: rgb(237 237 237) 2px 3px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 5px;
  padding: 10px 15px;
  cursor: pointer;
  border: 0;
  outline: 0;
  border-radius: 10px;
  color: #fff;
  margin-bottom: 15px;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  }

  > p {
    font-size: 13px;
    color: gray;
  }

  > img,
  svg {
    width: 28px;
    height: 28px;
  }
}

.kakao-btn {
  background-color: #fee500;
  color: rgb(0, 0, 0, 0.85);
}

.google-btn {
  background-color: #fff;
  color: #444;
}

.facebook-btn {
  background-color: #3d5a97;
  color: #fff;
}

.naver-btn {
  background-color: #03c75a;
}

.email-btn {
  background-color: #444;
}

.isChecked {
  > p {
    font-size: 13px;

    > a {
      text-decoration: underline;

      &:hover {
        font-weight: 600;
      }
    }
  }
}

//  서비스 메뉴

//팅젤문고

.ebook-image {
  max-width: 200px;
}

.ebook-detail-image {
  max-width: 300px;
}

//about 페이지

//about us

.H1 {
  word-break: keep-all;
  font-size: 60px;

  @media screen and (max-width: 768px) {
    font-size: 35px;
  }
}

.H2 {
  word-break: keep-all;
  font-size: 45px;

  @media screen and (max-width: 768px) {
    font-size: 25px;
  }
}

.H3 {
  word-break: keep-all;
  font-size: 40px;

  @media screen and (max-width: 768px) {
    font-size: 21px;
  }
}

.H4 {
  word-break: keep-all;
  font-size: 35px;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.lineHeight-70 {
  line-height: 70px;

  @media screen and (max-width: 768px) {
    line-height: 40px;
  }
}

.lineHeight-72 {
  line-height: 72px;

  @media screen and (max-width: 768px) {
    line-height: 43px;
  }
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.highLight {
  background-color: #ffce1f;
}

.mb-3 {
  margin-bottom: 30px;
}

.mt-3 {
  margin-top: 30px;
}

.mt-5 {
  margin-top: 50px;
}

.p-3 {
  padding: 30px;
}

.squareBold {
  font-family: $SquareBold;
}

.squareSemi {
  font-family: $SquareSemiBold;
}

.mainColor {
  color: #3b2477;
}

.StarBtns {
  background-color: #f3f6f9;
  border-radius: 16px;
  width: 300px;
  color: #444;
  padding: 10px 0;
  font-size: 40px;
  transition: all 300ms ease;

  &:hover {
    background-color: #ffce1f;
    color: #444;
    cursor: url('../../public/images/cursor.cur') 2 2, pointer;
  }

  @media screen and (max-width: 768px) {
    width: 150px;
    font-size: 23px;
  }
}

//멤버십

.MembershipCard {
  background-color: #fff;
  border-radius: 15px;
  color: #444;
  border: 1px solid #edeef1;
  box-shadow: rgba(237, 238, 241, 0.25) 0px 2px 8px;
  min-height: 200px;
  max-width: 350px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: none;
  }
}

.selected {
  border: 2px solid #7f5ad5;
}

.kakaoPayBtn,
.creditNormal {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #444;
  font-size: 16px;
}

.kakaoPayBtn {
  background-color: #ffeb02;
  padding: 8px 0;
  padding-right: 15px;
  padding-left: 10px;
  border-radius: 10px;
  border: 0;
  outline: 0;

  &:focus {
    border: 1px solid #444;
  }

  > picture > source,
  img {
    width: 70px;
  }

  > span {
    font-weight: 600;

    @media screen and (max-width: 680px) {
      display: none;
    }
  }
}

.creditNormal {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0.95px 0.95px 1.6px;
  padding: 8px 20px;
  border-radius: 10px;
  border: 0;
  outline: 0;

  &:focus {
    border: 1px solid #444;
  }

  > svg {
    margin-right: 10px;
    width: 28px;
    height: 28px;
  }

  > span {
    font-weight: 600;
    word-break: keep-all;
  }
}

.creditCard,
.KakaoPayBox {
  width: 100%;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.PwElement {
  width: 60px;
}

.youtube {
  > article {
    width: 100%;
    max-width: 640px;

    @media screen and (max-width: 768px) {
      width: 100% !important;
    }
  }
}

.modal-draw {
  cursor: pointer;

  &.active {
    border: 2px solid #63d959;
  }
}

.Twinkle-ani {
  animation: twinkle 1s 20;
  padding: 0;
}

@keyframes twinkle {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
